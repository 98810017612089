/* ---------- General ---------- */
html, body {
    margin: 0;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.foot {
    bottom: 0;
}

/* ---------- Color Scheme ---------- */

.foot, .banner {
    background-color: #2d55ca;
}

.index-nav, .nav {
    background: linear-gradient(to bottom, #2d55ca, hsla(120, 100%, 50%, 0));
}

html {
    background-color: #202020;
}

.link:hover {
    background-color: #ffffff;
}

.link:hover {
    color: #2d55ca;
}

html, .link, .foot {
    color: #ffffff;
}

/* ---------- Flex, Alignment, and Positioning ---------- */

.nav, .about, .dev, .com-dev, .foot, .dev-container,
.link-container, .banner, .logo-title-container,
.image-gallery, .text-container {
    display: flex;
}

.dev-container {
    flex-direction: column;
}

.about, .dev, .com-dev {
    flex-wrap: wrap;
}

.banner, .logo-title-container, 
.text-container {
    flex-direction: column;
}

.com-dev {
    flex-direction: row-reverse;
}

.dev, .com-dev, .foot, .dev-container,
.banner, .link-container, .logo-title-container,
.text-container {
    align-items: center;
}

.nav {
    align-items: flex-start;
}

.dev, .com-dev, .foot, .dev-container,
.banner, .about, .logo-title-container,
.gallery-item, .text-container {
    justify-content: center;
}

.nav, .link-container {
    justify-content: space-between;
}

.index-nav, .logo-title-container, .logo-small {
    position: fixed;
}

.link-container, .foot {
    position: absolute;
}

.page {
    position: relative;
}

/* ---------- Navigation ---------- */

.index-nav {
    opacity: 1;
    z-index: 2;
}

.nav {
    opacity: 1;
}

/* TODO: implement this */
.nav-hidden {
    transition: opacity 0.5s ease;
    display: none;
}

.banner-title-hidden {
    opacity: 0;
    visibility: hidden;
}

.logo {
    transition: all 1s ease;
}

.log, .logo-small {
    z-index: 3;
}

.link-container {
    z-index: 1;
}

.link {
    text-decoration: none;
    font-weight: bold;
    margin-right: 1em;
}

/* ---------- Gallery ---------- */

.image-gallery {
    overflow-x: auto;
    white-space: nowrap;
}

img {
    display: block;
    margin: 0 auto;
}

/* ---------- Not Found ---------- */

.not-found-container {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
}

.not-found-container h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.not-found-container p {
    font-size: 1.2em;
    color: #666;
}

.home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #0056b3;
}

/* ---------- Dynamic Sizing/Location ---------- */

html, body {
    height: 100%;
    width: 100%;
}

.page {
    min-height: 100vh;
}

.content {
    padding-bottom: 17.5vh;
}

.foot {
    width: 100vw;
    height: 10vh;
}

.index-nav, .nav {
    width: 100vw;
    height: 10vh;
}

.logo-title-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo-title-container-shrunk {
    position: fixed;
    left: 15em;
}

.link-container {
    width: 100%;
    justify-content: end;
    gap: 1em;
    transition: all .5s ease;
}

.link {
    padding: 1%;
    border-top: .15vw solid #ffffff;
    border-bottom: .15vw solid #ffffff;
    transition: all 1s ease;
}

video {
    width: 100%;
    height: auto;
    z-index: -1;
}

.about {
    gap: 10vw;
}

.dev, .com-dev{
    gap: 2vh;
}

.image-gallery {
    padding: 2vh;
}

.gallery-item {
    margin-right: .5em;
}

/* ---------- Fixed Sizing ---------- */

html, body {
    font: 16px;
}

.index-nav {
    padding-top: .5em;
}

.logo-title-container {
    top: 500px;
}

.banner-title {
    font-size: 2.75em;
}

.logo {
    height: 20em;
    width: 30em;
    margin-left: 1em;
    margin-right: 1em;
}

.logo-small {
    transition: all 1s ease;
    height: 8em;
    width: 12em;
    top: .5%; 
    left: .5%;
}

.image {
    height: 25em;
    width: 40em;
}

.text {
    width: 40em;
}

.description {
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
}

img {
    height: 35em;
    width: 50em;
}

.quote {
    width: 640px;
    height: 800px;
}

/* ---------- Responsive Sizing/Location ----------*/

@media screen and (max-width: 1650px) {
    .logo-title-container {
        top: 450px;
    }
}

@media screen and (max-width: 1550px) {
    .logo {
        height: 18em;
        width: 27em;
    }

    .logo-small {
        height: 8em;
        width: 12em;
    }

    .banner-title {
        font-size: 2.5em;
    }
    
    .link {
        font-size: smaller;
    }
}

@media screen and (max-width: 1500px) {
    .logo-title-container {
        top: 400px;
    }
    
    .logo-title-container-shrunk {
        top: 60px;
    }
}

@media screen and (max-width: 1380px) {
    .logo {
        height: 16em;
        width: 24em;
    }

    .logo-small {
        height: 8em;
        width: 12em;
    }

    .banner-title {
        font-size: 2.25em;
    }
    
    .link-container {
        gap: 1vw;
    }
}

@media screen and (max-width: 1300px) {
    .logo-title-container {
        top: 375px;
    }
}

@media screen and (max-width: 1250px) {
    .logo {
        height: 14em;
        width: 21em;
    }

    .logo-small {
        height: 8em;
        width: 12em;
    }

    .banner-title {
        font-size: 2em;
    }
}

@media screen and (max-width: 1200px) {
    .logo-title-container {
        top: 325px;
    }
}

@media screen and (max-width: 1090px) {
    .logo-title-container {
        top: 300px;
    }
    
    .logo-small {
        height: 5.5em;
        width: 8.5em;
    }
}

@media screen and (max-width: 950px) {
    .logo-title-container {
        top: 275px;
    }
    
    img {
        height: 30em;
        width: 45em;
    }
}

@media screen and (max-width: 800px) {
    .logo-title-container {
        top: 200px;
    }
    
    .logo {
        height: 12em;
        width: 18em;
    }

    .logo-small{
        height: 4em;
        width: 6em;
    }

    .banner-title {
        font-size: 1.75em;
    }

    .text {
        width: 30em;
    }
}

@media screen and (max-width: 750px) {
    .logo-title-container {
        top: 175px;
    }

    .logo {
        height: 10em;
        width: 15em;
    }

    .logo-small{
        height: 4em;
        width: 6em;
    }

    .banner-title {
        font-size: 1.5em;
    }
    
    h1 {
        font-size: 1.5em;
    }

    .image {
        height: 22.5em;
        width: 35em;
    }

    img {
        height: 20em;
        width: 35em;
    }

    .link {
        font-size: .9em;
    }
}

@media screen and (max-width: 650px) {
    .image {
        height: 20em;
        width: 30em;
    }
    
    .quote {
        width: 500px;
        height: 600px;
    }

    .link {
        font-size: .85em;
    }
}

@media screen and (max-width: 600px) {
    img {
        height: 18em;
        width: 23em;
    }

    .link {
        font-size: .75em;
    }
}

@media screen and (max-width: 550px) {
    .logo-title-container {
        top: 150px;
    }

    .logo {
        height: 8em;
        width: 12em;
    }

    .banner-title {
        font-size: 1.25em;
    }

    .link {
        font-size: .65em;
    }
}

@media screen and (max-width: 530px) {
    .image {
        height: 16em;
        width: 25em;
    }
    
    .quote {
        width: 400px;
        height: 500px;
    }

    .text {
        width: 25em;
    }
}

@media screen and (max-width: 450px) {
    .logo-title-container {
        top: 125px;
    }

    .logo {
        height: 6em;
        width: 9em;
    }

    .banner-title {
        font-size: 1em;
    }
}

@media screen and (max-width: 430px) {
    .image {
        height: 13em;
        width: 22em;
    }

    img {
        height: 10em;
        width: 15em;
    }

    .text {
        width: 20em;
    }

    .quote {
        width: 300px;
        height: 400px;
    }

    .link {
        font-size: .5em;
    }
}

@media screen and (max-width: 375px) {
    .logo-title-container {
        top: 100px;
    }

    .logo {
        height: 5em;
        width: 7.5em;
    }

    .banner-title {
        font-size: .75em;
    }

    .link {
        font-size: .45em;
    }

    .text {
        width: 15em;
    }

    .image {
        height: 11em;
        width: 18em;
    
    }
}

@media screen and (max-width: 300px) {
    .logo-title-container {
        top: 75px;
    }

    .logo {
        height: 4em;
        width: 6em;
    }

    .logo-small {
        height: 3em;
        width: 4.5em;
    }

    .banner-title {
        font-size: .5em;
    }

    .image {
        height: 9em;
        width: 14em;
    }

    .link {
        font-size: .4em;
    }

    .text {
        width: 12.5em;
    }

    h2 {
        font-size: 1.2em;
    }

    h3 {
        font-size: 1em;
    }
}

@media screen and (max-width: 225px) {
    .logo-title-container {
        top: 60px;
    }

    .logo {
        height: 3em;
        width: 4.5em;
    }

    .banner-title {
        font-size: .4em;
    }

    .link {
        font-size: .35em;
    }

    .text {
        width: 10em;
    }

    .image {
        height: 8em;
        width: 13em;
    }
}